<template>
  <list-template :table-config="tableConfig" :table-data="tableData" :total="total" :loading="loading" :current-page="page" :search-config="searchConfig" @onSearch="onSearch" @onChangePage="handleCurrentChange" @onHandle="handleClick" @onSupplement="handleAdd">
    <template slot="title">
      <Paper :visible.sync="isVisibleDialog" title="问卷详情" :info="info"></Paper>
    </template>
  </list-template>
</template>

<script>
import SearchBlock from '@/components/SearchBlock'
import Paper from '../components/Paper'
import { getApi2 } from '@/api'
import { tableListMixin } from '@/utils/mixins'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  name: 'List',
  components: { SearchBlock, Paper },
  mixins: [tableListMixin],
  created() {
    this.getSearchConfig()
  },
  methods: {
    getSearchConfig() {
      getApi2('/evaluation/result/get-range').then(res => {
        const { grade, class: classList, school } = res
        this.searchConfig[0].options = school
        this.searchConfig[1].options = grade
        this.searchConfig[2].options = classList
      })
      getApi2('/evaluation/sum-up/get-param').then(res => {
        const { history_status, theme } = res
        this.searchConfig[3].options = theme
        this.searchConfig[4].options = history_status
      })
    },
    getData() {
      this.getDataApi('/api2/api/evaluation/sum-up/record')
    },
    handleAdd() {
      this.$router.push('./add')
    },
    handleClick(row, handle) {
      const { type, text } = handle
      if (['edit', 'view'].includes(type)) {
        if (row.history_status === 1) {
          this.$router.push(`./summary?id=${row.id}${row.draft ? '&edit=1' : ''}`)
          return
        }
        if(row.history_status === 3 && row.draft) {
          this.$router.push(`./add?id=${row.id}&edit=1`)
          return
        }
        this.$router.push('./detail?id=' + row.id)
      } else if (type === 'paper') {
        if (text === '无') return
        this.handleShowDialog(row.id)
      }
    },
    handleShowDialog(id) {
      getApi2('/evaluation/sum-up/questionnaire', { id }).then(res => {
        this.info = res
      })
      this.isVisibleDialog = true
    }
  },
  data() {
    return {
      info: {},
      isVisibleDialog: false,
      searchConfig: [
        {
          tag: 'select',
          placeholder: '请选择校区',
          prop: 'school_id',
          options: [],
          label: 'name',
          value: 'id',
          change: (val, search) => {
            this.search = search
            if (search.grade_id) this.search.grade_id = ''
            if (search.class_id) this.search.class_id = ''
            getApi2('/evaluation/result/get-range', { school_id: val }).then(res => {
              const { grade, class: classList } = res
              this.searchConfig[1].options = grade
              this.searchConfig[2].options = classList
            })
          }
        },
        {
          tag: 'select',
          placeholder: '请选择年级',
          prop: 'grade_id',
          options: [],
          label: 'name',
          value: 'id',
          change: (val, search) => {
            this.search = search
            if (search.class_id) this.search.class_id = ''
            const { school_id, grade_id } = search
            getApi2('/evaluation/result/get-range', { school_id, grade_id }).then(res => {
              const { class: classList } = res
              this.searchConfig[2].options = classList
            })
          }
        },
        {
          tag: 'select',
          placeholder: '请选择行政班',
          prop: 'class_id',
          options: [],
          label: 'name',
          value: 'id'
        },
        {
          tag: 'select',
          prop: 'theme_id',
          placeholder: '请选择咨询主题',
          options: [],
          label: 'sub_name',
          value: 'id'
        },
        {
          tag: 'select',
          prop: 'history_status',
          placeholder: '请选择状态',
          options: [],
          label: 'name',
          value: 'id'
        },
        {
          tag: 'input',
          prop: 'student',
          placeholder: '输入学生姓名'
        },
        {
          tag: 'input',
          prop: 'student_no',
          placeholder: '输入学生学号'
        }
      ],
      tableConfig: [
        {
          type: 'number',
          label: '序号',
          width: '80rem',
          fixed: 'fixed'
        },
        {
          prop: 'student_name',
          label: '学生姓名',
          width: '100rem',
          fixed: 'fixed'
        },
        {
          prop: 'student_no',
          label: '学号',
          width: '140rem',
          fixed: 'fixed'
        },
        {
          prop: 'chamber',
          label: '咨询地点',
          width: '220rem'
        },
        {
          prop: 'start_at',
          label: '咨询时间',
          width: '340rem'
        },
        {
          prop: 'theme',
          label: '咨询主题',
          width: '180rem'
        },
        {
          prop: 'class_name',
          label: '行政班',
          width: '140rem'
        },
        {
          prop: 'grade_name',
          label: '年级',
          width: '140rem'
        },
        {
          prop: 'school_name',
          label: '校区',
          width: '180rem'
        },
        {
          prop: 'paper',
          label: '问卷详情',
          render(row) {
            return [{ type: 'paper', text: row.paper || '无' }]
          }
        },
        {
          prop: 'history_status_text',
          label: '咨询状态',
          width: '100rem',
          fixed: 'right',
        },
        {
          label: '操作',
          width: '120rem',
          fixed: 'right',
          handle: true,
          buttons: (row) => {
            let arr = []
            if (row.history_status === 1) {
              arr = [row.draft ? { type:"edit", text:"编辑" } : { type:"edit", text:"记录" }]
            } else if(row.history_status === 3 && row.draft) {
              arr = [{ type:"edit", text:"编辑" }]
            } else {
              arr = [{ type:"view", text:"详情" }]
            }
            return arr
          }
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  &-tabs {
    padding-right: 40rem;
    margin-bottom: 20rem;
  }
}
</style>
